
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Property, PropertyValue } from './types';
import { gql, ApolloClient, InMemoryCache } from '@apollo/client';
import { RootState } from '../../app/store';

export interface PropertiesState {
    allProperties: Property[];
    selectedCategoryId: number | null;
    appState: {
        isPropertiesListLoaded: boolean;
    }
}

const initialState: PropertiesState = {
    allProperties: [],
    selectedCategoryId: null,
    appState: {
        isPropertiesListLoaded: false
    }
}


export const propertiesSlice = createSlice({
    name: 'properties',
    initialState,
    reducers: {
        propertyChanged: (state, action: PayloadAction<Property>) => {
            const property = action.payload;
            const propertyIndex = state.allProperties.findIndex(x => x.id === property.id);
            state.allProperties[propertyIndex] = property;
        },
        propertyValueChanged: (state, action: PayloadAction<PropertyValue>) => {
            const propertyValue = action.payload;
            const propertyIndex = state.allProperties.findIndex(x => x.id === propertyValue.propertyId);
            const property = state.allProperties[propertyIndex];
            const propertyValueIndex = property.propertyValues.findIndex(x => x.id === propertyValue.id);
            property.propertyValues[propertyValueIndex] = propertyValue;
        }
        
    }
});

export const selectPropertyById = (state: RootState, id: number) => {
    return state.properties.allProperties.find(x => x.id === id);
}

export const selectPropertyValuesByPropertyId = (state: RootState, propertyId: number) => {
    return state.properties.allProperties.find(x => x.id === propertyId)!.propertyValues;
}

export const selectPropertyValueById = (state: RootState, propertyId: number, id: number) => {
    return state.properties.allProperties.find(x => x.id === propertyId)?.propertyValues.find(x => x.id === id);
}
                
export const { propertyChanged, propertyValueChanged } = propertiesSlice.actions;
export default propertiesSlice.reducer;