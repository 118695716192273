import React, {useEffect} from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, GridActionsCellItem, GridActionsColDef, GridColType,
    GridRowParams, GridCellParams, MuiEvent, GridEventListener, GridRowId, GridToolbarContainer, GridRowSelectionModel,
    GridCallbackDetails, useGridApiContext } from '@mui/x-data-grid';
import {Property, CategoryProperty} from "./types";
import {queries, mutations} from "./properties.gql"
import { useQuery, useMutation } from '@apollo/client';
import AddIcon from "@mui/icons-material/Add";
import AddPropertyDialog from "./addPropertyDialog";
import SelectedCategoryControl from "../shared/selectedCategoryControl";
import {useSearchParams} from "react-router-dom"

interface EditToolbarProps {
  onToggleShowAllProperties: (value: boolean) => {}
}


const EditToolbar: React.FC<EditToolbarProps> = ({onToggleShowAllProperties}) => {

  const [isAddPropertyDialogOpen, setIsAddPropertyDialogOpen] = React.useState(false);

  function handleAddPropertyClick() {
    setIsAddPropertyDialogOpen(true);
  }

  function handleAddPropertyDialogClosed() {
    setIsAddPropertyDialogOpen(false);
  }

  return (
    <GridToolbarContainer>


      <Button color="primary" startIcon={<AddIcon />} onClick={() => handleAddPropertyClick()}>
        Добавить свойство
      </Button>

      <AddPropertyDialog open={isAddPropertyDialogOpen} onClosed={() => handleAddPropertyDialogClosed()} />

      <SelectedCategoryControl onToggleShowAllProperties={onToggleShowAllProperties}/>
    </GridToolbarContainer>
  );
}

interface PropertiesListProps {
  selectPropertyToEdit: Function
}

const PropertiesList: React.FC<PropertiesListProps> = ({selectPropertyToEdit}) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCategoryId : number | null = searchParams.get('categoryId') && parseInt(searchParams.get('categoryId')!) || null ;
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [selectedCellParams, setSelectedCellParams] = React.useState<GridCellParams | null>(null);
  const [showAllProperties, setShowAllProperties] = React.useState(false);

  const {loading, error, data} = useQuery(queries.getAllProperties, {
    notifyOnNetworkStatusChange: true,
  });


  const {loading: categoryPropertiesLoading, error: categoryPropertiesError, data: categoryPropertiesData} 
  = useQuery(queries.getCategoryPropertiesByCategoryId, {
    notifyOnNetworkStatusChange: true,
    variables: {categoryId: selectedCategoryId},
    skip: !selectedCategoryId
  });
 
  // const [updatePropertyName, 
  //     { loading : changePropertyNameLoading, error: changePropertyNameError, data: changePropertyNameData }] 
  //       = useMutation(mutations.updatePropertyName);

  const [updateProperty, 
    { loading : changePropertyLoading, error: changePropertyError, data: changePropertyData }] 
      = useMutation(mutations.updateProperty);

    const processRowUpdate = React.useCallback(
      async (newRow: Property) => {
          // var result = await updatePropertyName({variables: {
          //   "input": {
          //     "propertyId": newRow.id,
          //     "name": newRow.name
          //   }
          // }})

          var result = await updateProperty({variables: {
            input: {
              property: {
                id: newRow.id,
                name: newRow.name,
                multipleSelection: newRow.multipleSelection,
                displayOrder: 0,
                propertyValues: [],
                categoryProperties: []
              },
            }
          }})

          newRow.name = result.data.updateProperty.property.name;
          newRow.multipleSelection = result.data.updateProperty.property.multipleSelection;

          return newRow;
      },
      [],
    );


    const [addCategoryProperty, {loading: addCategoryPropertyLoading, error: addCategoryPropertyError, data: addCategoryPropertyData}]
        = useMutation(mutations.addCategoryProperty, {
          refetchQueries: [{
            query: queries.getCategoryPropertiesByCategoryId,
            variables: {categoryId: selectedCategoryId}
          }]
        });

    const [deleteCategoryProperty, {loading: deleteCategoryPropertyLoading, error: deleteCategoryPropertyError, data: deleteCategoryPropertyData}]
        = useMutation(mutations.deleteCategoryProperty, {
          refetchQueries: [{
            query: queries.getCategoryPropertiesByCategoryId,
            variables: {categoryId: selectedCategoryId}
          }]
        });

    useEffect(() => {
      refreshRowsSelection();
      
    }, [selectedCategoryId, data, categoryPropertiesData]);
  
    const refreshRowsSelection = () => {
      if(selectedCategoryId && categoryPropertiesData && categoryPropertiesData.categoryPropertiesByCategoryId){
        setRowSelectionModel((categoryPropertiesData.categoryPropertiesByCategoryId as {propertyId: number}[]).map(x => x.propertyId))
      }
    }

      const handleStartCellEdit = (params: GridCellParams, event: MuiEvent<React.SyntheticEvent>) => {
        setSelectedCellParams(params);

        if(params.field === 'propertyValues'){
          event.defaultMuiPrevented = true;
          selectPropertyToEdit(params.row.id);
        }
      };

      const handleStopCellEdit = (params: GridCellParams) => {
        //const apiRef = useGridApiContext();
        setSelectedCellParams(null);
      }

      if (loading) return (<>Loading...</>);
      if (error) return (<>Error! ${error.message}</>);

      const columns: (GridColDef | GridActionsColDef) [] = [
        {
          field: 'name',
          headerName: 'Название',
          width: 150,
          editable: true
        },
        {
          field: 'multipleSelection',
          headerName: 'Множественный выбор',
          type: 'boolean',
          width: 150,
          editable: true
        },
        {
          field: 'propertyValues',
          headerName: 'Значения',
          description: '',
          editable: true,
          sortable: false,
          width: 300,
          valueGetter: (params: GridValueGetterParams) =>
            `${(params.row as Property).propertyValues.map(x => x.value)}`,
        }
      ];

      const handleRowSelectionChange = (newRowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
        const added = newRowSelectionModel.length > rowSelectionModel.length;

        if(added){
          const newPropertyId = newRowSelectionModel.find(x => !rowSelectionModel.includes(x))
          addCategoryProperty({variables: {
            input: {
              categoryId: selectedCategoryId,
              propertyId: newPropertyId
            }
          }})
        } else {
          const deletedPropertyId = rowSelectionModel.find(x => !newRowSelectionModel.includes(x));
          deleteCategoryProperty({variables: {
            input: {
              categoryId: selectedCategoryId,
              propertyId: deletedPropertyId
            }
          }})
        }

        //setRowSelectionModel(newRowSelectionModel);
      }

      const properties = showAllProperties 
          ? data.properties 
          : categoryPropertiesData && categoryPropertiesData.categoryPropertiesByCategoryId && (data.properties as Property[]).filter(x =>  
              !!(categoryPropertiesData.categoryPropertiesByCategoryId as CategoryProperty[]).find((cp) => cp.propertyId === x.id)) || []

    return (<>
      <Box sx={{ height: '100%', width: '100%' }}>
         <DataGrid sx={{border: 0}}
            rows={properties}
            columns={columns}
            //pageSize={50}
            //rowsPerPageOptions={[5]}
            hideFooter={true}
            rowHeight={38}
            onCellEditStart = {handleStartCellEdit}
            onCellEditStop = {handleStopCellEdit}
            processRowUpdate = {processRowUpdate}
            onProcessRowUpdateError={(error) => {
              console.error(error);
            }}
            slots = {{
              toolbar: EditToolbar
            }}
            slotProps = {{
              toolbar: {onToggleShowAllProperties: (val: boolean)=> setShowAllProperties(val)}
            }}
            checkboxSelection = {!!selectedCategoryId}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={handleRowSelectionChange}
            isRowSelectable={(params: GridRowParams) => 
              selectedCategoryId && categoryPropertiesData?.categoryPropertiesByCategoryId
              && !(categoryPropertiesData.categoryPropertiesByCategoryId as CategoryProperty[]).find(x => x.categoryId !== selectedCategoryId && x.propertyId === params.row.id)}
            disableRowSelectionOnClick={true}
            //experimentalFeatures={{ newEditingApi: true }}
            />
        </Box>
    </>)
}

export default PropertiesList;