
import React, {useState, useEffect} from 'react'
import {TextField, Box, Divider, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material'
import {Property, PropertyValue} from './types'
import { DataGrid, GridColDef, GridActionsColDef, GridCellModesModel, GridToolbarContainer, 
  GridRowsProp, GridCellModes, GridActionsCellItem } from '@mui/x-data-grid';
import {queries, mutations} from "./properties.gql"
import { useQuery, useMutation, gql } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import DeleteConfirmationDialog from './deleteConfirmationDialog'

interface EditToolbarProps {
  propertyId: number,
  setRows: (newRows: (oldRows: PropertyValue[]) => PropertyValue[]) => void;
  setCellModesModel: (
    newModel: (oldModel: GridCellModesModel) => GridCellModesModel,
  ) => void;
  toggleEditState: (isEditing: boolean) => void
}

const EditToolbar: React.FC<EditToolbarProps> = ({propertyId, setRows, setCellModesModel}) => {

  const handleClick = () => {

    const id=0;
    setRows((oldRows) => [{ id , propertyId, value: '', displayOrder: 0 }, ...oldRows]);
    setCellModesModel((oldModel) => ({
      ...oldModel,
      [id]: { value: {mode: GridCellModes.Edit} },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={() => handleClick()}>
        Добавить значение
      </Button>
    </GridToolbarContainer>
  );
}

interface PropertyValuesListParams {
  propertyId: number,
  //onSavePropertyValue: (propertyValue: PropertyValue) => Promise<PropertyValue>,
  toggleEditState: (isEditing: Boolean) => void
}

const PropertyValuesList: React.FC<PropertyValuesListParams> = ({propertyId, toggleEditState}) => {

  const [rows, setRows] = React.useState<PropertyValue[]>([]);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const [deletePropertyValue, setDeletePropertyValue] = React.useState<PropertyValue | null>(null);

  const {loading, error, data} = useQuery(queries.getPropertyById, {
    variables: {
      "id": propertyId
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRows([...data.properties[0].propertyValues])
      toggleEditState(false);
    }
  });



  const [changePropertyValue, 
    { loading : changePropertyValueLoading, error: changePropertyValueError, data: changePropertyValueData }] 
        = useMutation(mutations.updatePropertyValue);

  const [addPropertyValue, {loading: addPropertyValueLoading, error: addPropertyValueError, data: addPropertyValueData}]
        = useMutation(mutations.addPropertyValue, {
          refetchQueries: [{
            query: queries.getPropertyById,
            variables: {
              "id": propertyId
            }
          }]
        });

  const columns: GridColDef [] = [
      {
        field: 'value',
        headerName: ' Значение',
        width: 400,
        editable: true,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 50,
        align: 'right',
        cellClassName: 'actions',
        getActions: ({ row }) => {
  
        return [

            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => setDeletePropertyValue(row)}
              color="inherit"
            />,
          ];
        },
      },
    ];

    const processRowUpdate = React.useCallback(
      async (row: PropertyValue) => {

        if(row.id === 0) {         
            const result = await addPropertyValue({variables: {
              "input": {
                  "propertyId": propertyId,
                  "value": row.value
              }
            }})
        
            const propertyValue = result.data.addPropertyValue.propertyValue as PropertyValue;

            setCellModesModel((oldModel) => ({
              ...oldModel,
              [propertyValue.id]: { value: {mode: GridCellModes.View} },
            }));

            return  propertyValue;
        } else {

          const result = await changePropertyValue({variables: {
            "input": {
                "propertyValueId": row.id,
                "value": row.value
            }
          }})
          return result.data.updatePropertyValue.propertyValue;
        }
      },
      [],
    );

    const handleProcessRowUpdateError = (error: string) => {
      console.error("ProcessRowUpdateError: " + error);
    }

    if (loading) return (<>Loading...</>);
    if (error) return (<>Error! ${error.message}</>);

    return (<>
    <Box sx={{ height: 400, width: '100%', minWidth: '400px' }}>
       <DataGrid
          cellModesModel={cellModesModel}
          rowHeight={38}
          onCellModesModelChange={(model) => setCellModesModel(model)}
          onProcessRowUpdateError = {handleProcessRowUpdateError}
          columns={columns}
          rows={rows}
          onCellEditStart = {() => toggleEditState(true)}
          onCellEditStop = {() => toggleEditState(false)}
          processRowUpdate={processRowUpdate}
          slots = {{
            toolbar: EditToolbar
          }}
          slotProps = {{
            toolbar: {propertyId, setRows, setCellModesModel, toggleEditState}
          }}
          hideFooter={true}
          />
          </Box>
          <DeleteConfirmationDialog propertyValue={deletePropertyValue} onClosed={() => setDeletePropertyValue(null)} />
    </>)
}


export default PropertyValuesList;