import React from 'react';
import {Product, PropertyValue} from './products.types'
import {useQuery, useMutation} from '@apollo/client'
import {queries, mutations} from './products.gql'
import {Autocomplete, TextField, createFilterOptions} from '@mui/material'

interface PropertyValueEditorProps {
    propertyId: number,
    product: Product
}

interface PropertyValueOption {
    inputValue?: string;
    id: number;
    value: string;
  }

const filter = createFilterOptions<PropertyValueOption>();

const PropertyValueEditor: React.FC<PropertyValueEditorProps> = ({propertyId, product}) => {

    const [value, setValue] = React.useState<PropertyValueOption | null>(
        product?.propertyValues.filter((x : PropertyValue) => x.propertyId == propertyId)?.map((x : PropertyValue) => {
            return {
                id: x.id,
                value: x.value
            }
        })[0]);

    const[options, setOptions] = React.useState<PropertyValueOption[]> ([])

    const fillOptions = (propertyValues: PropertyValue[]) => {
      setOptions(propertyValues.map((x : PropertyValue) => {
        return {
            id: x.id,
            value: x.value
        }
      }))
    }

    const {loading, error, data} = useQuery(queries.getPropertyValuesByPropertyId, {
        notifyOnNetworkStatusChange: true,
        variables: {propertyId: propertyId},
        onCompleted: (data) => fillOptions(data.propertyValues)
    });

    const [addProductPropertyValue, {
      loading: addProductPropertyValueLoading, 
      error: addProductPropertyValueError, 
      data: addProductPropertyValueData}]
    = useMutation(mutations.addProductPropertyValue, {
      refetchQueries: [{
        query: queries.getProductById,
        variables: {
          "id": product.id
        }
      }]
    });

    const [clearProductPropertyValues, { 
        loading: clearProductPropertyValuesLoading, 
        error: clearProductPropertyValuesError, 
        data: clearProductPropertyValuesData}]
    = useMutation(mutations.clearProductPropertyValues, {
      refetchQueries: [{
        query: queries.getProductById,
        variables: {
          "id": product.id
        }
      }]
    });

    const [addProductPropertyNewValue, {
      loading: addProductPropertyNewValueLoading, 
      error: addProductPropertyNewValueError, 
      data: addProductPropertyNewValueData}]
    = useMutation(mutations.addProductPropertyNewValue, {
      refetchQueries: [{
        query: queries.getProductById,
        variables: {
          "id": product.id
        }},
        {
          query: queries.getPropertyValuesByPropertyId,
          variables: {propertyId: propertyId}
        }]
    });


    if (loading) return <p></p>;
    if (error) return <p>Error :(</p>;

    const handleValueChange = (event: React.SyntheticEvent, newValue: string | PropertyValueOption | null, reason: string) => {
        if(typeof newValue === 'string') {
          console.log("added string value: " + newValue)
        }
        else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              console.log("save inputValue value: " + newValue.inputValue)
              addProductPropertyNewValue({
                variables: {
                  input: {
                    productId: product.id,
                    propertyId: propertyId,
                    value: newValue.inputValue
                  }
                }
              })

            } else {
                if(newValue) {
                  // Add product property value
                  addProductPropertyValue({
                      variables: {
                          input: {
                              productId: product.id,
                              propertyValueId: newValue.id
                          }
                      }
                  })
                } else {
                  // Delete product property value
                  clearProductPropertyValues({
                    variables: {
                        input: {
                            productId: product.id,
                            propertyId: propertyId
                        }
                    }
                  });
                }
            }
        };

    return (<Autocomplete
        disablePortal
        options={options}
        renderOption={(props, option) => <li {...props}>{option.value}</li>}
        sx={{ width: 300 }}
        value = {value}
        selectOnFocus = {false}
        openOnFocus
        freeSolo
        onChange={handleValueChange}
        filterOptions={(options, params) => {
            const filtered = filter(options, params);
    
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.value);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                id: -1,
                value: `Добавить: "${inputValue}"`,
              });
            }
    
            return filtered;
          }}
          
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params}  />}
        getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.value;
          }}
        />
        )
}

export default PropertyValueEditor;