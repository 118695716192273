import {useState} from "react"
import {Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button} from "@mui/material"
import {mutations, queries} from "./properties.gql"
import {useMutation} from "@apollo/client"

interface AddPropertyDialogParams {
    open: boolean,
    onClosed: () => void
}


const AddPropertyDialog: React.FC<AddPropertyDialogParams> = ({open, onClosed}) => {
    
    const [name, setName] = useState<string>("");   

    const [addProperty, {loading: addPropertyLoading, error: addPropertyError, data: addPropertyData}]
    = useMutation(mutations.addProperty, {
        variables: {
            "input": {
                "name": name
            }
        },
        refetchQueries: [{
            query: queries.getAllProperties
          }]
    });

    const handleSave = async () => {
        await addProperty();
        onClosed();
    }

    return (<>
    <Dialog open={open} onClose={onClosed}>
        <DialogTitle>Новое свойство</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Название"
            fullWidth
            variant="standard"
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosed}>Отмена</Button>
          <Button onClick={handleSave}>Сохранить</Button>
        </DialogActions>
      </Dialog>
      </>)
}

export default AddPropertyDialog