import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import PropertiesPage from './features/manageProperties/propertiesPage';
import ProductProperties from './features/productProperties/productProperties';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        path: "/properties",
        element: <PropertiesPage/>,
      },
      {
        path: "/product-props",
        element: <ProductProperties/>,
      },
    ]
  },
  
]);

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
  cache: new InMemoryCache(),
  connectToDevTools: true
});

client
  .query({
    query: gql`
      query {
          categories {
            id,
            name,
            parentCategoryId
          }
      }
    `,
  })
  .then((result) => console.log(result));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
        {/* <BrowserRouter>
          <App />
        </BrowserRouter> */}
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
