import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Box, IconButton, FormControlLabel, Switch} from '@mui/material';
import {useQuery} from '@apollo/client';
import {queries} from '../categories/category.gql'
import {Category} from '../categories/types';
import ClearIcon from '@mui/icons-material/Clear'

interface SelectedCategoryControlProps {
    onToggleShowAllProperties: (value: boolean) => {}
}

const SelectedCategoryControl: React.FC<SelectedCategoryControlProps> = ({onToggleShowAllProperties}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const categoryId = searchParams.get('categoryId');
    const [category, setCategory] = useState<Category | null>(null);
    const [showAllProperties, setShowAllProperties] = useState(false);
    

    const {loading, error, data, refetch} = useQuery(queries.findCategoryById, {
        variables: {
          "id": parseInt(categoryId!)
        },
        onCompleted: (data) => {
            setCategory({...data.categories[0]})
        },
        skip: !categoryId
      });

      useEffect(() => {
        if(!!categoryId){
            refetch({
                "id": parseInt(categoryId!)
              });
        } else {
            setCategory(null);
        }
      }, [categoryId])

    const resetSelectedCategory = () => {
        searchParams.delete('categoryId');
        setSearchParams(searchParams);
    }

    const handleShowAllPropertiesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllProperties(event.target.checked);
        onToggleShowAllProperties(event.target.checked);
      };

    return (
        <div>
            <span>Категория: {category && category.name || "Не выбрана"}</span>
            {!!category && <IconButton size="small" onClick={() => resetSelectedCategory()}><ClearIcon/></IconButton>}
            {!!category && <FormControlLabel control={<Switch 
            checked={showAllProperties}
            onChange={handleShowAllPropertiesChange} />} label="Показать все" />}
        </div>
    )
}

export default SelectedCategoryControl;