import React from 'react'
import PropertiesList from './propertiesList';
import PropertyValuesDialog from './propertyValuesDialog';


const PropertiesPage: React.FC = () => {

    const [editingPropertyId, setEditingPropertyId] = React.useState<number | null>(null);

    const selectPropertyToEdit = (propertyId: number) => {
        setEditingPropertyId(propertyId);
    }

    const handlePopupClose = () => {
        setEditingPropertyId(null);
    }

    return (
        <>
            <PropertiesList selectPropertyToEdit={selectPropertyToEdit} />
            {editingPropertyId && 

            <PropertyValuesDialog open={!!editingPropertyId} propertyId={editingPropertyId}
                onClosed={handlePopupClose}>
            </PropertyValuesDialog>}
            
        </>
    )
}

export default PropertiesPage;