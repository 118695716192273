import React from 'react';
import {Product, PropertyValue} from './products.types'
import {useQuery, useMutation} from '@apollo/client'
import {queries, mutations} from './products.gql'
import {Autocomplete, TextField, createFilterOptions, Checkbox, AutocompleteValue} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface PropertyValueEditorProps {
    propertyId: number,
    product: Product
}

interface PropertyValueOption {
    inputValue?: string;
    id: number;
    value: string;
  }

const filter = createFilterOptions<PropertyValueOption>();

const PropertyMultipleValueEditor: React.FC<PropertyValueEditorProps> = ({propertyId, product}) => {

    const [selectedValues, setSelectedValues] = React.useState<PropertyValueOption[]>(
        product?.propertyValues.filter((x : PropertyValue) => x.propertyId == propertyId)?.map((x : PropertyValue) => {
            return {
                id: x.id,
                value: x.value,
            }
        }));

    const[options, setOptions] = React.useState<PropertyValueOption[]> ([])

    const fillOptions = (propertyValues: PropertyValue[]) => {
      setOptions(propertyValues.map((x : PropertyValue) => {
        return {
            id: x.id,
            value: x.value,
        }
      }))
    }

    const {loading, error, data} = useQuery(queries.getPropertyValuesByPropertyId, {
        notifyOnNetworkStatusChange: true,
        variables: {propertyId: propertyId},
        onCompleted: (data) => fillOptions(data.propertyValues)
    });

    const [addProductPropertyValue, {
      loading: addProductPropertyValueLoading, 
      error: addProductPropertyValueError, 
      data: addProductPropertyValueData}]
    = useMutation(mutations.addProductPropertyValue, {
      refetchQueries: [{
        query: queries.getProductById,
        variables: {
          "id": product.id
        }
      }]
    });

    const [deleteProductPropertyValues, { 
        loading: deleteProductPropertyValuesLoading, 
        error: deleteProductPropertyValuesError, 
        data: deleteProductPropertyValuesData}]
    = useMutation(mutations.deleteProductPropertyValues, {
      refetchQueries: [{
        query: queries.getProductById,
        variables: {
          "id": product.id
        }
      }]
    });

    const [addProductPropertyNewValue, {
      loading: addProductPropertyNewValueLoading, 
      error: addProductPropertyNewValueError, 
      data: addProductPropertyNewValueData}]
    = useMutation(mutations.addProductPropertyNewValue, {
      refetchQueries: [{
        query: queries.getProductById,
        variables: {
          "id": product.id
        }},
        {
          query: queries.getPropertyValuesByPropertyId,
          variables: {propertyId: propertyId}
        }]
    });


    if (loading) return <p></p>;
    if (error) return <p>Error :(</p>;

    const handleValueChange = (event: React.ChangeEvent<{}>, value: AutocompleteValue<PropertyValueOption, true, true, true>, reason: string) => {
      const options = value as PropertyValueOption[];

      console.info(reason);
        if (reason == "selectOption") {
          if (options[options.length - 1].id == -1) {
            addProductPropertyNewValue({variables: { input: {productId: product.id, propertyId: propertyId, value: options[options.length - 1].inputValue}}})
          } else {
            addProductPropertyValue({variables: { input: {productId: product.id, propertyValueId: options[options.length - 1].id }}})
          }
          
        } else if (reason == "removeOption" || reason == "clear") {
          let propertyValuesIdsToDelete = selectedValues.filter((x : PropertyValueOption) => !options.includes(x)).map(x => x.id);
          deleteProductPropertyValues({
            variables: { 
              input: {
                productId: product.id,
                propertyValueIds: propertyValuesIdsToDelete
          }}});
        }
        setSelectedValues(value as PropertyValueOption[]);
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (<Autocomplete
        disablePortal
        options={options}
        sx={{ width: 300, backgroundColor: "#fff", position: "absolute" }}
        value = {selectedValues}
        openOnFocus
        freeSolo
        onChange = {handleValueChange}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.value}
          </li>
        )}
        multiple
        disableCloseOnSelect
        //limitTags={1}
        filterOptions={(options, params) => {
            const filtered = filter(options, params);
    
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.value);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                id: -1,
                value: `Добавить: "${inputValue}"`,
              });
            }
    
            return filtered;
          }}
          
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params}  />}
        getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.value;
          }}
        />
        )
}

export default PropertyMultipleValueEditor;