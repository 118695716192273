import React, {useState} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import PropertyValuesList from './propertyValuesList';

interface EditPropertyDialogProps {
    open: boolean,
    onClosed: Function,
    children?: React.ReactNode,
    propertyId: number
}

const PropertyValuesDialog : React.FC<EditPropertyDialogProps> = function ({ children, open, onClosed, propertyId }) {

    const [isEditing, setIsEditing] = React.useState<Boolean>(false);

    const handleClose = () => {
        onClosed();
    };

    return (
        <Dialog open={open}>
            <DialogContent>
                <PropertyValuesList propertyId={propertyId} toggleEditState={(isEditing) => setIsEditing(isEditing)}></PropertyValuesList>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={!!isEditing}>ОК</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PropertyValuesDialog;