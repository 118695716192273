import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {queries} from './products.gql';
import {Product, Property, CategoryProperty} from './products.types';
import {Box} from '@mui/material';
//import {DataGrid, GridColDef, GridActionsColDef, GridValueGetterParams, GridRenderEditCellParams} from '@mui/x-data-grid';
import {DataGridPro, GridColDef, GridActionsColDef, GridValueGetterParams, GridRenderEditCellParams} from '@mui/x-data-grid-pro';
import PropertyValueEditor from './propertyValueEditor';
import PropertyValueEditorProps from './propertyMultipleValueEditor';
import styles from './productProperties.module.css';


// function EditPropertyValueCellComponent (params: GridRenderEditCellParams) {
//     return (
//         <PropertyValueEditor pro/>
//     )
// }

const ProductProperties: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedCategoryId : number | null = searchParams.get('categoryId') && parseInt(searchParams.get('categoryId')!) || null ;

    const {loading, error, data: getProductsData } = useQuery(queries.getProductsByCategoryId, {
        variables: {categoryId: selectedCategoryId},
        notifyOnNetworkStatusChange: true,
        skip: !selectedCategoryId
    })

    const { loading: propertiesLoading, error: propertiesError, data: getPropertiesData } = 
            useQuery(queries.getPropertiesByCategoryId, {
                variables: {categoryId: selectedCategoryId},
                notifyOnNetworkStatusChange: true,
                skip: !selectedCategoryId
            });


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    // const mapProductsAndPropertiesToRows = (products: Product[]) => {
    //     return products.map(
    //         p => {
    //             const newProduct : any = {
    //                 id: p.sku,
    //                 name: p.name,
    //                 sku: p.sku,
    //                 published: p.published,
    //                 price: p.price,

    //             };

    //             p.propertyValues.forEach(pv => {
    //                 if(newProduct.hasOwnProperty(pv.propertyId.toString())) {
    //                     newProduct[pv.propertyId.toString()].push(pv);
    //                 } else {
    //                     newProduct[pv.propertyId.toString()] = [pv];
    //                 }
    //             })

    //             return newProduct;
    //         }
    //     );
    // }

    const rows = (selectedCategoryId && getProductsData?.productsByCategoryId) 
        ? getProductsData.productsByCategoryId //mapProductsAndPropertiesToRows(getProductsData.productsByCategoryId as Product[])
        : [];

    const permanentColumns: (GridColDef | GridActionsColDef) [] = [
        {
            field: 'name',
            headerName: 'Название',
            width: 300,
            editable: false
        },
        {
            field: 'sku',
            headerName: 'Артикул',
            width: 150,
            editable: false
        },
        {
            field: 'published',
            headerName: 'Вкл.',
            width: 50,
            editable: false,
            type: 'boolean'
        },
        {
            field: 'price',
            headerName: 'Цена',
            width: 100,
            editable: false
        },
    ];

    //const [columns, setColumns] = React.useState<(GridColDef | GridActionsColDef) []>(permanentColumns);

    // React.useEffect((
    //     () => {
    //         if (selectedCategoryId && getPropertiesData?.categories[0]) {
    //             setColumns(permanentColumns.concat(getPropertiesData?.categories[0]?.categoryProperties?.map((x: CategoryProperty) : GridColDef => {
    //                 return {
    //                     field: x.property.id.toString(),
    //                     headerName: x.property.name,
    //                     editable: true,
    //                     sortable: false,
    //                     width: 100,
    //                 };
    //             })));
    //         }
    //     }
    // ), [selectedCategoryId, getPropertiesData?.categories, getProductsData ]);

    const propertyValueGetter = (p: Property, gridParams: GridValueGetterParams) => {
        return `${(gridParams.row as Product)?.propertyValues?.filter(pv => pv.propertyId == p.id).map(x => x.value)}`
    }

    const propertyEditor = (p: Property, gridParams: GridRenderEditCellParams) => {
        if(p.multipleSelection) {
            return (<PropertyValueEditorProps propertyId={p.id} product={gridParams.row} />)
        } else {
            return (<PropertyValueEditor propertyId={p.id} product={gridParams.row} />)
        }
            
    }

    const columns: (GridColDef | GridActionsColDef) [] = 
        (selectedCategoryId && getPropertiesData?.propertiesByCategoryId)
        ? permanentColumns.concat(getPropertiesData.propertiesByCategoryId.map((p: Property) : GridColDef => {
            return {
                field: p.id.toString(),
                headerName: p.name,
                editable: true,
                sortable: false,
                width: 200,
                valueGetter: (params) => propertyValueGetter(p, params),
                renderEditCell: (params) => propertyEditor(p, params)
            };
        }))
        : permanentColumns;

    return (<Box sx={{ height: '100%', width: '100%' }}>
                <DataGridPro sx={{border: 0}}
                rows={rows}
                columns={columns}
                hideFooter={true}
                rowHeight={38}
                initialState={{ pinnedColumns: { left: ['name', 'sku', 'published'] } }}
                //onCellEditStart = {handleStartCellEdit}
                //onCellEditStop = {handleStopCellEdit}
                //processRowUpdate = {processRowUpdate}
                //rowSelectionModel={rowSelectionModel}
                //onRowSelectionModelChange={handleRowSelectionChange}
                />
   </Box>)
}

export default ProductProperties;