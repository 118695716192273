import React from 'react'
import {Button, Dialog, DialogTitle, DialogActions} from '@mui/material'
import {Property, PropertyValue} from './types'
import {queries, mutations} from "./properties.gql"
import { useQuery, useMutation, gql } from '@apollo/client';


interface DeleteConfirmationDialogParams {
    propertyValue: PropertyValue | null,
    onClosed: Function
  }

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogParams> = ({propertyValue, onClosed}) => {

    const [deletePropertyValue, {loading: deletePropertyValueLoading, error: deletePropertyValueError, data: deletePropertyValueData}]
          = useMutation(mutations.deletePropertyValue, {
            variables: {
              "input": {
                "propertyValueId": propertyValue?.id
              }
            },
            refetchQueries: [{
              query: queries.getPropertyById,
              variables: {
                "id": propertyValue?.propertyId
              }
            }]
          });
  
    const handleDelete = async () => {
      await deletePropertyValue();
      onClosed();
    }
  
    if(!propertyValue) return null;
  
    return (<>
      <Dialog
          open={!!propertyValue}
          onClose={() => onClosed()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Удалить {propertyValue!.value}?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => onClosed()}>Нет</Button>
            <Button onClick={() => handleDelete()} autoFocus>Да</Button>
          </DialogActions>
        </Dialog>
    
    </>);
  }

export default DeleteConfirmationDialog