
import React, {useEffect} from 'react';
import { useQuery, gql } from '@apollo/client';
import { Category } from './types';
import { TreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { queries } from './category.gql';
import { useSearchParams } from 'react-router-dom';

const CategoryNode: React.FC<{category: Category, all: Category[]}> = ({category, all}) => {

    return <TreeItem nodeId={category.id.toString()} label={category.name} >
        {all.filter(x => x.parentCategoryId === category.id).map((child: Category) => ( 
            <CategoryNode key={child.id} category={child} all={all}></CategoryNode>
        ))}
    </TreeItem>
}

const CategoriesTree : React.FC = () => {
    const { data, loading, error } = useQuery(queries.getAllCategories);
    const [searchParams, setSearchParams] = useSearchParams();

    const [selected, setSelected] = React.useState<string>();

    const selectedCategoryId = searchParams.get('categoryId');

    useEffect(() => {
        if(!!selectedCategoryId) {
            setSelected(selectedCategoryId);
        } else {
            setSelected(undefined);
        }
    }, [selectedCategoryId])
    
    if (loading) {
        return <div>Loading...</div>;
    }
    
    if (error) {
        return <div>Error!</div>;
    }

    const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => {
        searchParams.set('categoryId', nodeIds );
        setSearchParams(searchParams);
    }

    return (
        <div>
            <Box sx={{textAlign: 'left', pt: 1}} fontSize={12}>
                <TreeView
                    
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{ flexGrow: 1, overflowY: 'auto', textAligh: 'left' }}
                    onNodeSelect={handleSelect}
                    selected={selected}
                    >
                {data.categories.filter((x: Category) => x.parentCategoryId === 0).map((category: Category) => (
                    <CategoryNode key={category.id} category={category} all={data.categories}></CategoryNode>
                ))}
                </TreeView>
            </Box>
        </div>
    );
};

export default CategoriesTree;