import './App.css';
import  CategoriesTree from './features/categories/categoriesTree';
import { Drawer, Toolbar, Box, CssBaseline, Button, AppBar as MuiAppBar, Typography, AppBarProps as MuiAppBarProps,
  IconButton, Menu as MenuIcon  } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropertiesPage from './features/manageProperties/propertiesPage';
import {Outlet, Link, useNavigate} from 'react-router-dom';
import React, { MouseEventHandler } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function App() {

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const buttonHandler = (e: any, path: string) => {
    e.preventDefault();
    navigate(path);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ height: '100%'}} className="App">
      <CssBaseline />
       <AppBar position="fixed" open={open}>
        <Toolbar>
          <Button color="inherit" sx={{ mr: 2, ...(open && { display: 'none' }) }} onClick={handleDrawerOpen}>Категории</Button>
          <Button color="inherit" onClick={e => buttonHandler(e, '/properties')}>Свойства</Button>
          <Button color="inherit"  onClick={e => buttonHandler(e, '/product-props')}>Товары</Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="persistent" anchor="left" open={open}
        sx={{
          width: drawerWidth,
          //height: '100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          display: 'flex',
          flexDirection: 'column'
        }}>
          <DrawerHeader>
            <Typography variant="h6" noWrap component="div">
              Категории
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <CategoriesTree></CategoriesTree>
      </Drawer>

      <Main open={open}
        sx={{ height: '100%', flexGrow: 1, bgcolor: 'background.default', display: 'flex', flexDirection: 'column'}}
      >
        <Toolbar/>
        <Box sx={{flexGrow: 1}}>
          <Outlet></Outlet>
        </Box>
        
      </Main>

    </Box>
  );
}

export default App;
