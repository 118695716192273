import { gql, ApolloClient, InMemoryCache } from '@apollo/client';

export const queries = {
    getAllProperties: gql`
    query {
        properties {
            id,
            name,
            multipleSelection,
            displayOrder
            propertyValues {
              id,
              value,
              displayOrder,
              propertyId
            }
          }
    }`,

    getPropertyById: gql`
      query getPropertyById($id: Int) {
        properties (where: {id: {eq: $id}}) {
          id,
          name,
          propertyValues {
            id,
            value,
            displayOrder,
            propertyId
          }
        }
      }
    `,

    getPropertyValuesByPropertyId: gql`
    query getPropertyValuesByPropertyId($propertyId: Int) {
      propertyValues (where: {propertyId: {eq: $propertyId}}) {
        id,
        propertyId,
        value
      }
    }`,

    getCategoryPropertiesByCategoryId: gql`
      query getCategoryPropertiesByCategoryId ($categoryId: Int!) {
        categoryPropertiesByCategoryId(categoryId: $categoryId){
          id,
          categoryId,
          propertyId
        }
    }
    `
}

export const mutations = {
  addProperty: gql`
  mutation addProperty ($input: AddPropertyInput!){
    addProperty(input: $input){
      property {
        id,
        name
      }
    }
  }`,


  updatePropertyName: gql`
      mutation updatePropertyName ($input: UpdatePropertyNameInput!) {
        updatePropertyName(input: $input) {
          property {
            id
            name
          }
        }
      }
  `,

  updateProperty: gql`
    mutation updateProperty ($input: UpdatePropertyInput!) {
      updateProperty (input: $input) {
        property {
          id,
          multipleSelection,
          name,
          displayOrder
        }
      }
    }
  `,

  updatePropertyValue: gql`
    mutation updatePropertyValue ($input: UpdatePropertyValueInput!) {
      updatePropertyValue(input: $input) {
        propertyValue {
          id,
          value
        }
      }
    }
  `,

  addPropertyValue: gql`
    mutation addPropertyValue ($input: AddPropertyValueInput!) {
      addPropertyValue (input: $input) {
        propertyValue {
          id,
          value,
          propertyId
        }
      }
    }
  `,

  deletePropertyValue: gql`
    mutation deletePropertyValue ($input: DeletePropertyValueInput! ) {
      deletePropertyValue (input: $input) {
        boolean
      }
    }
  `,

  addCategoryProperty: gql`
    mutation addCategoryProperty($input: AddCategoryPropertyInput!){
      addCategoryProperty (input: $input){
        categoryProperty {
          id,
          categoryId,
          propertyId
        }
      }
    }
  `,

  deleteCategoryProperty: gql`
  mutation deleteCategoryProperty ($input: DeleteCategoryPropertyInput!) {
    deleteCategoryProperty (input: $input) {
      boolean
    }
  }
  `
}

