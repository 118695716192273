import gql from 'graphql-tag';

export const queries = {
    getAllCategories: gql`
        query {
            categories {
            id,
            name,
            parentCategoryId
            }
        }
`,

    findCategoryById: gql`
        query findCategoryById ($id: Int) {
            categories(where: {id: {eq: $id}}){
                id,
                name
            }
        }
    `
}