import {gql} from "@apollo/client"

export const queries =  {
    getProductsByCategoryId: gql`
    query getProductsByCategoryId ($categoryId: Int!) {
        productsByCategoryId (categoryId: $categoryId) {
            id,
            name,
            sku,
            published,
            price
            propertyValues {
              id,
              propertyId,
              value
            }
        }
      } 
    `,

    getProductById: gql`
    query getProductById ($id: Int!) {
        products (where: {id: {eq: $id}}) {
            id,
            name,
            sku,
            published,
            price
            propertyValues {
              id,
              propertyId,
              value
            }
        }
      } 
    `,

    getPropertiesByCategoryId1: gql`
        query getPropertiesByCategoryId ($id: Int) {
            categories(where: {id: {eq: $id}}){
                categoryProperties {
                    property {
                    id,
                    name
                    }
                }
            }
        }
    `,

    getPropertiesByCategoryId: gql`
        query getPropertiesByCategoryId ($categoryId: Int!) {
            propertiesByCategoryId(categoryId: $categoryId){
                    id,
                    name,
                    multipleSelection
                }
            }`,

    getPropertyValuesByProductId: gql`
        query getPropertyValuesByProductId ($productId: Int!) {
            products (where: {id: {eq: $productId }}){
                propertyValues {
                id,
                propertyId,
                value
                }
            }
        }
    `,

    getPropertyValuesByPropertyId: gql`
        query getPropertyValuesByPropertyId($propertyId: Int) {
            propertyValues (where: {propertyId: {eq: $propertyId}}) {
            id,
            propertyId,
            value
            }
        }
    `
}

export const mutations = {
    addProductPropertyValue: gql`
    mutation addProductPropertyValue ($input: AddProductPropertyValueInput!){
        addProductPropertyValue(input: $input){
          boolean
        }
    }
    `,

    deleteProductPropertyValues: gql`
    mutation deleteProductPropertyValues($input: DeleteProductPropertyValuesInput!) {
        deleteProductPropertyValues (input: $input) {
          boolean
        }
      }
    `,

    clearProductPropertyValues: gql`
    mutation clearProductPropertyValues($input: ClearProductPropertyValuesInput!) {
        clearProductPropertyValues (input: $input) {
          boolean
        }
      }
    `,
    

    addProductPropertyNewValue: gql`
    mutation addProductPropertyNewValue ($input: AddProductPropertyNewValueInput!){
        addProductPropertyNewValue(input: $input){
          boolean
        }
    }
    `
}